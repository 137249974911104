.contact {
  width: 100%;
  height: 80vh;
  display: flex;
  color: white;
}

.contact .left {
  height: 100%;
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  text-align: center; /* Center text */
}

.contact .left .image-wrapper {
  display: flex;
  justify-content: center; /* Center image horizontally */
  align-items: center;
  max-width: 270px;
  max-height: 380px;
}

.contact .left p {
  padding-top: 20px;
}

.contact .right {
  height: 100%;
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .right h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 50px;
  margin-left: 25px;
}

form {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 30px;
}

form input {
  height: 40px;
  width: 80%;
  padding-left: 4px;
  background-color: #212b33;
  border-radius: 8px;
}

form textarea {
  height: 70px;
  width: 80%;
  padding-left: 4px;
  padding-top: 2px;
  color: white;
  background-color: #212b33;
  border-radius: 8px;
}

form input::placeholder,
form textarea::placeholder {
  font-weight: bold;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

form label {
  margin-top: 15px;
  margin-bottom: 5px;
}

form button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  background-color: #212b33;
  font-size: 15px;
  cursor: pointer;
}
