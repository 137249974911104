.portfolio {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    background-color: rgb(17 24 39);
}

.portfolio .portfolioTitle {
    font-size: 60px;
    color: white;
}

.portfolioList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
}

.portfolioItem {
    width: 300px;
    height: 350px;
    margin: 20px;
    box-shadow: 0px 3px 15px #2F7892;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 18px;
}

.portfolioItem:hover {
    box-shadow: 2px 5px 15px #0ADD1B;
    transition: 0.3s ease-in;
    cursor: pointer;
}

.portfolioItem .projectImage {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.portfolioItem .projectName {
    font-size: 24px;
    font-weight: bold;
}

.portfolioItem .projectDesc {
    font-size: 16px;
}

@media only screen and (max-width: 1300px) {
    .portfolioList {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 800px) {
    .portfolioList {
        grid-template-columns: 1fr;
    }
}
