.footer {
    width: 100%;
    height: 200px;
    background-color: rgb(17 24 39);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    color: white;
  }
  
  .icons svg {
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
  }
  
  .footer p {
    text-align: center;
  }